/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&display=swap');

/*@media only screen and (max-width: 480px) {*/

* {
    letter-spacing: 0.01em;
}
a:link { text-decoration: none } 
/* SideBar */
.sidebar {
    position: absolute;
    left: 0%;
    right: 86.11%;
    top: 0%;
    bottom: 0%;

    /* White */

    background: #FFFFFF;
}
.username {
    display: inline-block;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 12px;
    white-space:normal;
    max-width: 125px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: #5E5F5F;
}
.css-10650aq {
    -webkit-box-flex:1;
    -webkit-flex-grow:1;
    -ms-flex-positive:1;
    flex-grow:1;
    overflow:auto;
    min-height:100%;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.css-1iwdxm {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.ADITrackerByHeading {
    /* ADI Tracker by */
    width: 150px;
    height: 25px;
    /* Heading 3 */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    align-items: baseline;
    /* or 25px */
    /* Orange */
    color: #D36B28;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    
}
.ADITrackerByLabel {
    /* ADI Tracker by */
    margin: 0px;
    /* Heading 3 */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* or 25px */
    /* Orange */
    color: #8B8B8B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;    
}

/* Frame 60 */
.Frame60 {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 150px;
    height: 90.7px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* Line 4 */
.Line4 {
    width: 200px;
    height: 0px;
    /* Gray150 */
    border: 1px solid #D6D6D6;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.Line3 {
    width: 200px;
    height: 0px;
    /* Gray150 */
    border: 1px solid #D6D6D6;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.Frame77 {
    /* Frame 77 */
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;

    position: absolute;
    width: 125px;
    height: 46px;
    left: 37.5px;
    bottom: 96px;
}

.Frame4 {
    /* Frame 4 */
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: left;
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    gap: 16px;

    width: 200px;
    height: 104px;
}

.Performance {
    /* Performance */
    width: 140px;
    height: 26px;
    /* Heading 1 */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    /* Dark Grey */
    color: #5E5F5F;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.PerformanceText {
    /* Choose one or more institutions and the desired economic class, to view and compare their financial performance. */
    /* width: 698px; */
    height: 21px;
    /* Body Big */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    /* Gray400 */
    color: #8B8B8B;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.Frame65 {
    /* Frame 65 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    /* width: 698px; */
    height: 51px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.Frame12 {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 762px;
    height: 62px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.Frame11 {
    /* Frame 11 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 200px;
    height: 62px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.Institution {
    /* Institution */
    width: 100%;
    height: 14px;
    /* Label Big */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    white-space: nowrap;
    /* identical to box height, or 14px */
    /* Gray400 */
    color: #8B8B8B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.Frame1 {
    /* Frame 1 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 11px;
    width: 200px;
    height: 40px;
    position: relative;
    /* low shadow */
    /* filter: drop-shadow(0px 4px 4px rgba(229, 229, 229, 0.5)); */
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    outline: none;
}
.Frame1_2 {
    /* Frame 1 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 11px;
    width: 200px;
    height: 40px;
    position: relative;
    /* low shadow */
    /* filter: drop-shadow(0px 4px 4px rgba(229, 229, 229, 0.5)); */
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    outline: none;
}

select.icon-menu {
    /* */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 63px;
    width: 200px;
    height: 40px;
    outline: none;
    
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    padding-left: 56px;
    padding-right: 20px;
    color: #5E5F5F;
    /* White */
    background: #FFFFFF;
    border-radius: 8px;
    border-color: #FFFFFF;
    border-style: solid;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.bankOutline {
    /* bank-outline */
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    left: 18px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

select.icon-menu option {
    /* Institution */
    width: 61px;
    height: 14px;
    /* Label Big */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    /* Dark Grey */
    color: #5E5F5F;
    /* White */
    background: #FFFFFF;
    border-radius: 8px;
    border-color: #FFFFFF;
    border-style: solid;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.icon-menu:hover {
    cursor: pointer;
    transition-duration: 0.5s;
    border-color: #D36B28 !important;
}
.icon-menu:focus {
    border-color: #D36B28; 
}
select.icon-menu option:hover::after {
    background: rgba(211, 107, 40, 0.1);
    border-radius: 8px;
    color:#D36B28;
}

.arrowDropdown {
    position: relative;
    right: 0px;
    top: 1px;
    transform: rotate(0.25turn);
}

.arrowSubMenu {
    position: relative;
    right: 10px;
    top: 0px;
}

.dropdownHeading {
    /* Body Big */
     font-family: 'Urbanist';
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 150%;
     height: 37px;
     align-items: center;
     display: flex;
     flex-direction: row;
     width: 97%;
     padding-left: 0px;
     gap: 8px;
     /* identical to box height, or 21px */
     /* Dark Grey */
     color: #5E5F5F;
 }
 
 .dropdownHeading:hover {
     background: rgba(211, 107, 40, 0.1) !important;
     border-radius: 8px;
 
 }

.searchBar {
    display: flex;
    background-color: #F0F0F0;
    border-radius: 8px;
    height: 37px;
    width: 97%;
    border-style: none;
    font-weight: 500;
    padding-left: 8px 16px;
    gap: 16px;
    align-items: center;
    flex-direction: row;
    margin-bottom: 8px;
}

.labelInstitutionFilter {
    display: flex;
    background-color: #F0F0F0;
    border-radius: 8px;
    height: 37px;
    width: 97%;
    border-style: none;
    font-weight: 500;
    padding: 8px 16px;
    margin-left: 20px;
    gap: 16px;
    align-items: center;
    flex-direction: row;
    outline: none;
}
.labelInstitutionFilter:focus .labelInstitutionFilter:active {
    outline: none !important;
    border-color: #D36B28;
}

.input:focus {
    outline: none;
}

a {
    white-space: nowrap;
}

.dropdownText {
   /* Body Big */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    height: 37px;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 97%;
    padding-left: 20px;
    gap: 8px;
    /* identical to box height, or 21px */
    /* Dark Grey */
    color: #5E5F5F;
}

.dropdownText:hover {
    background: rgba(211, 107, 40, 0.1) !important;
    border-radius: 8px;

}

.dropdownTextTimeline {
    /* Body Big */
     font-family: 'Urbanist';
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 150%;
     height: 25px;
     align-items: center;
     display: flex;
     flex-direction: row;
     width: 80%;
     padding-left: 5px;
     /* identical to box height, or 21px */
     /* Dark Grey */
     color: #5E5F5F;
 }
 .dropdownTextTimeline:hover {
    background: rgba(211, 107, 40, 0.1) !important;
    border-radius: 8px;

}

.scroll_container {
    width:100%;
    height:350px;
    color:#999999;
    border:2px solid #FFF;
    border-radius:10px;
    padding-bottom: 0px;
    overflow:hidden !important;
    text-align:justify;
}

.scroll_content {
    position:relative;
    width:420px;
    height:105%;
    top:-17px;
    padding:20px 0px 0px 0px;
    overflow-y:hidden !important;
    overflow-x:hidden !important;
}

.scroll_content_timeline {
    position:relative;
    width:100%;
    height:100%;
    top:0px;
    padding:0px 0px 0px 0px;
    overflow-y:hidden !important;
    overflow-x:hidden !important;
}

.track-vertical {
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 1px !important;
    z-index: inherit !important;
    border-radius: 3px;
    background: #D6D6D6;
    z-index: 1001 !important;
  }
  .track-vertical .thumb-vertical {
    position: relative;
    display: block;
    right: 1px;
    width: 3px !important;
    cursor: pointer;
    border-radius: inherit;
    background-color: #A9A9A9;
    
    z-index: 1001;
  }
  
  .track-horizontal {
    position: absolute;
    height: 6px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 3px;
  }
  .track-horizontal .thumb-horizontal {
    position: relative;
    display: block;
    height: 150%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(125, 149, 255, 0.993);
  }
  
  .view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: scroll;
    margin-right: -15px;
    margin-bottom: -17px !important;
  }

  .dropdownBase {
    /* Gray300 */
    position: relative;
    left: 4px;
    width: 95%;
    padding-left: 20px;
    border-top: 1px solid #A9A9A9;
    color: #D22121;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    display: flex;
    pointer-events: none !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px 16px;
    gap: 4px;
  }

  .cancelApply {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .imageButton {
    pointer-events: auto;
    position: relative;
    background-color: #FFF;
    border: none;
    cursor: pointer;
  }
  
  .checkbox-neo {
    width:16px;
    height: 16px;
    border-radius: 5px;
    vertical-align: middle;
    border: 1px solid #A9A9A9;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .checkbox-neo:checked {
    appearance: auto;
    clip-path: inset(0% 0% 0% 0% round 5px);
    accent-color: #D36B28;
  }

.ResetFilters {
    /* Reset filters */
    width: 71px;
    height: 14px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    margin-right: 16px;
    cursor: pointer;
    background: none;
    border: none;
    /* identical to box height, or 14px */
    text-decoration-line: underline;
    /* Dark Grey */
    color: #5E5F5F;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Frame6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px;
    gap: 11px;
    white-space: nowrap;
    width: 100px;
    height: 100%;
    filter: drop-shadow(0px 4px 4px rgba(229, 229, 229, 0.5));
}

.graphBackground {
    /* graph */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 2vh;
    gap: 24px;
    width: 100%;
    /* Gray100 */
    background: #E6E6E6;
    border-radius: 16px;   
    flex-grow: 1;
}


.graphType {
    /* Graph type */
    width: 72px;
    height: 14px;
    /* Label Big */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 5px;
    white-space: nowrap;
    /* identical to box height, or 14px */
    /* Gray400 */
    color: #8B8B8B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.graphFrame {
    padding-left: 24px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 5px;
}

.App-header {
  /* Graph */
    display: flex;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 24px;
    padding-left: 5vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    /* White */
    background: #FFFFFF;
    /* low shadow */
    box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.5);
    border-radius: 16px;
    /* Inside auto layout */
    position: relative;
    flex: 1;
    order: 1;
    align-self: stretch;
}

.App {
      /* Graph */
      /* low shadow */
      /* Inside auto layout **/
      /* height: 100%; */
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
}

.xAxisGrid {
    stroke: rgba(211, 107, 40, 0.2);
}

.graphHeading {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.timelineFrame {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    margin: 24px;
}

.timelineFrame2 {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    width: auto;
    gap: 4px;
}
.playButton {
    border: none;
    background: none;
}
.playButton:hover {
    background: none;
    cursor: pointer;
}

.timelineSelects {
    /* */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    width: 80px;
    height: 30px;
    /* White */
    background: #FFFFFF;
    border-radius: 16px;
    border: none;
}

.tooltip {
    max-width: 400px;
    border-radius: 16px;
    padding: 8px;
    font-size: 14px;
    font-family: 'Urbanist';
    font-weight: 600;
    background-color: #5E5F5F;
    position: absolute;
}

.tooltipMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tooltipMain_event {
    display: flex;
    border-radius: 16px;
    background-color: white;
    flex-direction: column;
    justify-content: space-between;
}

.subtooltip_a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    position: relative;
    border-radius: 16px;
    margin: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.subtooltip_e {
    font-size: 12px;
}

.nameChangeFrame {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 2px;
}

.subTooltip_b {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    border-radius: 16px;
    gap: 4px;
}

.subTooltip_c {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: right;
    width: max-content;
    background-color: white;
    border-radius: 16px;
    gap: 4px;
}


.subTooltipText {
    display: block;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    flex: 1;
    margin: 3px;
    margin-left: 12px;
    margin-right: 8px;
    width: 200px;
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nameChangeText {
    display: block;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    flex: 1;
    margin: 3px;
    margin-left: 12px;
    margin-right: 8px;
    width: 200px;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
}
.subTooltipTextNo {
    white-space: nowrap;
    align-items: flex-end;
    text-align: right !important;
    font-weight: 400;
    width: 30px;
    font-size: 12px;
    background-color: white;
    right: 12px;
    margin-right: 8px;
}
.circle {
    display: flex;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid black;
    margin: 0px;
}
.subTooltip_d {
    display: flex;
    align-items: center;
    background-color: white;
    align-items: center;
    width: 15px;
    border-radius: 16px;
    
}

.LearnMoreInfo {
    /* Frame 78 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    height: 20%;
    /* White */
    background: #FFFFFF;
    border-radius: 16px;
    /* Inside auto layout */
    flex: 1;
    order: 2;
    align-self: stretch;
}
.LearnMoreInfo2 {
    /* Frame 78 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;
    gap: 6px;
    /* White */
    background: #FFFFFF;
    border-radius: 16px;
    /* Inside auto layout */
}

.LearnMoreInfoText {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    /* Dark Grey */
    color: #5E5F5F;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.VisitOurWebsiteButton {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px;
    padding: 8px 12px;
    gap: 8px;
    width: 132px;
    height: 37px;
    /* Orange */
    cursor: pointer;
    background: #D36B28;
    border: none;
    border-radius: 18px;
    /* Inside auto layout */    
    flex: none;
    order: 1;
    flex-grow: 0;   
}

.VisitOurWebsiteText {
    /* Button */
    width: 108px;
    height: 21px;
    /* Body Big Bold */
    font-family: 'Urbanist';
    font-style: normal;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.contactus {
    color: #D36B28;
    text-decoration: underline !important;
}

.LearnMoreOuter {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
}

[data-amplify-authenticator] [data-amplify-container] {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    width: 100%;
    height: 80vh;
    min-height: 600px;
    max-height: 680px;
}
[data-amplify-authenticator] {
    width: 100%;
}

[data-amplify-router] {
    height: 100%;
    width: 100%;
    max-height: 850px;
    border-radius: 24px 24px 0px 0px;
}
[data-amplify-authenticator] [data-amplify-form] {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.loginContainer {
    display:flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 90vw;
    max-width: 480px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pliantBackground {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1;
    align-self: center;
    width: 100%;
    height: 20vh;
    margin-bottom: 2vh;
    max-width: 480px;
    min-height: 105px;
    max-height: 175px;
    background-image: url("../public/static/icons/PliantImageMobile.svg");
    background-size: cover;
    border-radius: 0px 0px 24px 24px;
}

.disclaimer {
    position: absolute;
    bottom: 0px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    /* or 21px */
    /* White */
    color: #FFFFFF;
}

.signUp {
    display: flex;
    flex-direction: column;
}

.signUpHeading {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #5E5F5F;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.signUpInfo {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 1vh;
    line-height: 120%;
    color: #8B8B8B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.signUpFooter {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1em;
    /* Gray300 */
    color: #A9A9A9;
}

.amplify-field-group  {
 background-color: white;
}

.amplify-field-group--horizontal {
    border: none;
}

.amplify-field-group__field-wrapper {
    border: none !important;
    border-color: red;
}
.amplify-input {
    border: none;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 0px;
    margin: 0px;
    padding-left: 12px;
    /* identical to box height, or 27px */
    /* Dark Grey */
    box-shadow: none;
    outline: none;
    color: #5E5F5F;
}
.amplify-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
.amplify-label {
    /* Heading 3 */
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    margin: 0px;
    margin-top: 1rem;
    color: #8B8B8B; 
}

.amplify-flex {
    gap: 0px;
}

.amplify-flex.amplify-field-group.amplify-field-group--horizontal {
    border-bottom: 2px solid #8B8B8B;
    border-radius: 0px;
}

.amplify-field-group__outer-end {
    position: relative;
    right: 0px;
    outline: none;
    border: none;
}

.amplfiy-field {
    gap: 0px !important;
}
.amplify-button.amplify-field-group__control.amplify-field__show-password {
    border: none;
    background: none;
    outline: none;   
    padding: 0px;
}
.amplify-button.amplify-field-group__control.amplify-field__show-password:focus {
    border: none;
    box-shadow: none;
    padding: 0px;
}
:root, [data-amplify-theme] {
    --amplify-colors-font-primary: black;
    /* you can also use references: */
    --amplify-colors-font-secondary: rgb(211, 107, 40, 1);
    --amplify-colors-brand-primary-100: rgb(211, 107, 40, 1);
    --amplify-colors-brand-primary-90: rgb(211, 107, 40, 0.9);
    --amplify-colors-brand-primary-80: rgb(211, 107, 40, 0.8);
    --amplify-colors-brand-primary-70: rgb(211, 107, 40, 0.7);
    --amplify-colors-brand-primary-60: rgb(211, 107, 40, 0.6);
    --amplify-colors-brand-primary-50: rgb(211, 107, 40, 0.5);
    --amplify-colors-brand-primary-40: rgb(211, 107, 40, 0.4);
    --amplify-colors-brand-primary-30: rgb(211, 107, 40, 0.3);
    --amplify-colors-brand-primary-20: rgb(211, 107, 40, 0.2);
    --amplify-colors-brand-primary-10: rgb(211, 107, 40, 0.1);
  }
  [data-amplify-authenticator-confirmsignup] {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 14px */
    /* Gray400 */
    color: #8B8B8B;
  }
  [data-amplify-authenticator-signup] > .amplify-flex > .amplify-flex > .amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield {
    display: none;
  }

  [data-amplify-authenticator-confirmsignup] > .amplify-flex > .amplify-flex > .amplify-button.amplify-field-group__control {
    width: 40%;
    align-self: center;
    background: none;
    outline: none;
    border: none;
    border-radius: 16px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
    /* or 25px */
    text-decoration: underline;
    display: flex;
    align-items: center;

    color: #8B8B8B;
  }
  [data-amplify-authenticator-confirmsignup] > .amplify-flex > .amplify-flex > .amplify-button.amplify-field-group__control.amplify-button--primary  {
    width: 40%;
    align-self: center;
    background: #D36B28;
    outline: none;
    border: none;
    border-radius: 16px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
    text-decoration: none;
    /* or 25px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }
  /* to remove when we want users to sign themselves in again */
  [data-amplify-authenticator-confirmsignup] > .amplify-flex > .amplify-flex > .amplify-button.amplify-field-group__control.amplify-button--primary  {
    display: none;
  }  
  [data-amplify-authenticator-confirmsignup] > .amplify-flex > .amplify-flex > .amplify-button.amplify-field-group__control  {
    display: none;
  }
  [data-amplify-authenticator-confirmsignup] > .amplify-flex > .amplify-flex > .amplify-flex.amplify-field.amplify-textfield {
    display: none;
  }
  [data-amplify-authenticator-confirmsignup] > .amplify-flex > .amplify-flex > .amplify-text.amplify-authenticator__subtitle {
    display: none;
  }
  .amplify-button.amplify-field-group__control.amplify-button--primary {
    width: 40%;
    align-self: center;
    background: #D36B28;
    box-shadow: 0px 8px 24px -2px rgba(211, 107, 40, 0.5);
    border-radius: 16px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
    /* or 25px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }
  
  .amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--fullwidth {
    width: 40%;
    align-self: center;
    background: #D36B28;
    box-shadow: 0px 8px 24px -2px rgba(211, 107, 40, 0.5);
    border-radius: 16px;
    margin-top: 2vh;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
    /* or 25px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }


  .amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small:hover {
    background: none;
  }  
  .amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small:focus {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }

  .amplify-tabs {
    display: none;
  }

  .css-10650aq {
    height: 100vh;
  }

.timelineSelectsText {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    /* Gray400 */
    color: #8B8B8B;
}
.main {
    margin-top: 1vw;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}
option {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    /* Gray400 */
    color: #8B8B8B;
}

.missingData_subheading {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
}

.missingData_a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    position: relative;
    border-radius: 16px;
    margin: 0px;
}
.missingData_e {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    position: relative;
    border-radius: 16px;
    margin: 0px;
    margin-top: 8px;
}
.missingData_b {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    flex: 1;
    justify-content: space-between;
    background-color: none;
    border-radius: 16px;
    gap: 4px;
}
.missingData_d {
    display: flex;
    align-items: center;
    background-color: none;
    align-items: center;
    width: 15px;
    margin-left: 10px;
    margin-right: 0px;
    border-radius: 16px;
}

.missingDataText {
    display: block;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    flex: 1;
    margin: 3px;
    margin-left: 8px;
    margin-right: 8px;
    max-width: 200px;
    background-color: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label {
    font-weight: 400;
    font-size: 12px;
    line-height: 1em;
    max-height: 2em;
    background-color: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.mobileHeadingMenu {
    background-color: #D36B28;
    color: white;
    margin-bottom: 6px;
    padding: 4px;
    border-radius: 8px 8px 0 0;
    padding: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    font-size: 16px;
    max-height: 1vh;
    width: 90vw;
    max-width: 460px;
    transform: translate(-50%, -120%);
}

.mobileButton {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;
}

/* Media Query (for mobile)
/* SideBar */
@media only screen and (max-width: 739px) {
    .sidebar {
        position: absolute;
        left: 0%;
        right: 86.11%;
        top: 0%;
        bottom: 0%;

        /* White */

        background: #FFFFFF;
    }

    .ADITrackerByHeading_m {
        /* ADI Tracker by */
        width: auto;
        height: 25px;
        /* Heading 3 */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        /* or 25px */
        /* Orange */
        color: #D36B28;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        
    }
    .ADITrackerByLabel {
        /* ADI Tracker by */
        margin: 0px;
        /* Heading 3 */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        /* or 25px */
        /* Orange */
        color: #8B8B8B;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;    
    }

    /* Frame 60 */
    .Frame60_m {
        /* Auto layout */
        position: absolute;
        right: 3vw;
        display: flex;
        flex-direction: row;
        align-items:    flex-end;
        padding: 0px;
        gap: 12px;
        width:  auto;
        height: auto;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    /* Line 4 */
    .Line4 {
        width: 200px;
        height: 0px;
        /* Gray150 */
        border: 1px solid #D6D6D6;
        /* Inside auto layout */
        flex: none;
        flex-grow: 0;
    }
    .Line3 {
        width: 200px;
        height: 0px;
        /* Gray150 */
        border: 1px solid #D6D6D6;
        /* Inside auto layout */
        flex: none;
        flex-grow: 0;
    }

    .LineMobile {
        width: 90vw;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        margin-top: 20px;
        margin-bottom: 7px;
        height: 0px;
        /* Gray150 */
        border: 1px solid #686868;
        opacity: 0.2;
        /* Inside auto layout */
        flex: none;
        flex-grow: 0;
        pointer-events: none;
    }
    .Frame77 {
        /* Frame 77 */
        /* Auto layout */

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 16px;

        position: absolute;
        width: 125px;
        height: 46px;
        left: 37.5px;
        bottom: 96px;
    }

    .Frame4 {
        /* Frame 4 */
        /* Auto layout */

        display: flex;
        flex-direction: column;
        align-items: left;
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        gap: 16px;

        width: 200px;
        height: 104px;
    }

    .Performance {
        /* Performance */
        width: 140px;
        height: 26px;
        /* Heading 1 */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        /* or 26px */
        /* Dark Grey */
        color: #5E5F5F;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .PerformanceText {
        /* Choose one or more institutions and the desired economic class, to view and compare their financial performance. */
        /* width: 698px; */
        /* Body Big */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 21px */
        /* Gray400 */
        color: #8B8B8B;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    .Frame65 {
        /* Frame 65 */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
        padding: 10px;
        gap: 4px;
        /* width: 698px; */
        height: 63px;
        /* Inside auto layout */
    }

    .Frame12 {
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        height: auto;
        width: auto;
        /* Inside auto layout */
        flex: none;
        flex-grow: 0;
    }
    .Frame11 {
        /* Frame 11 */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        margin: 0px;
        padding-left: 3vw;
        width: 94vw !important;
        height: auto;
    }

    .customDropdownToggleText {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Institution {
        /* Institution */
        width: 20vw;
        height: 14px;
        /* Label Big */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        /* identical to box height, or 14px */
        /* Gray400 */
        color: #8B8B8B;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .Frame1 {
        /* Frame 1 */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 11px;
        width: 66vw !important;
        height: auto;
        position: relative;
        /* low shadow */
        /* filter: drop-shadow(0px 4px 4px rgba(229, 229, 229, 0.5)); */
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        outline: none;
    }
    .Frame1_2 {
        /* Frame 1 */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 11px;
        width: 66vw !important;
        height: auto;
        position: relative;
        /* low shadow */
        /* filter: drop-shadow(0px 4px 4px rgba(229, 229, 229, 0.5)); */
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        outline: none;
    }
    select.icon-menu {
        /* */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        gap: 63px;
        width: 200px;
        height: 40px;
        outline: none;
        
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        padding-left: 56px;
        padding-right: 20px;
        color: #5E5F5F;
        /* White */
        background: #FFFFFF;
        border-radius: 8px;
        border-color: #FFFFFF;
        border-style: solid;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .bankOutline {
        /* bank-outline */
        width: 16px;
        height: 16px;
        position: absolute;
        top: 4px;
        left: 18px;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    select.icon-menu option {
        /* Institution */
        width: 61px;
        height: 14px;
        /* Label Big */
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */
        /* Dark Grey */
        color: #5E5F5F;
        /* White */
        background: #FFFFFF;
        border-radius: 8px;
        border-color: #FFFFFF;
        border-style: solid;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .icon-menu:hover {
        cursor: pointer;
        transition-duration: 0.5s;
        border-color: #D36B28 !important;
    }
    .icon-menu:focus {
        border-color: #D36B28; 
    }
    select.icon-menu option:hover::after {
        background: rgba(211, 107, 40, 0.1);
        border-radius: 8px;
        color:#D36B28;
    }

    .arrowDropdown {
        position: relative;
        right: 0px;
        top: 1px;
        width: 16px;
        height: 16px;
        transform: rotate(0.25turn);
    }

    .arrowSubMenu {
        position: relative;
        right: 10px;
        top: 0px;
    }

    .dropdownHeading {
        /* Body Big */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        height: 37px;
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 97%;
        padding-left: 0px;
        gap: 8px;
        /* identical to box height, or 21px */
        /* Dark Grey */
        color: #5E5F5F;
    }
    
    .dropdownHeading:hover {
        background: rgba(211, 107, 40, 0.1) !important;
        border-radius: 8px;
    
    }

    .searchBar {
        display: flex;
        background-color: #F0F0F0;
        border-radius: 8px;
        height: 37px;
        width: 97%;
        border-style: none;
        font-weight: 500;
        padding-left: 8px 16px;
        gap: 16px;
        align-items: center;
        flex-direction: row;
        margin-bottom: 8px;
    }

    .labelInstitutionFilter {
        display: flex;
        background-color: #F0F0F0;
        border-radius: 8px;
        height: 37px;
        width: 97%;
        border-style: none;
        font-weight: 500;
        padding: 8px 16px;
        margin-left: 20px;
        gap: 16px;
        align-items: center;
        flex-direction: row;
        outline: none;
    }
    .labelInstitutionFilter:focus .labelInstitutionFilter:active {
        outline: none !important;
        border-color: #D36B28;
    }

    .input:focus {
        outline: none;
    }

    a {
        white-space: nowrap;
    }

    .dropdownText {
    /* Body Big */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        height: 37px;
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 97%;
        padding-left: 20px;
        gap: 8px;
        /* identical to box height, or 21px */
        /* Dark Grey */
        color: #5E5F5F;
    }

    .dropdownText:hover {
        background: rgba(211, 107, 40, 0.1) !important;
        border-radius: 8px;

    }

    .dropdownTextTimeline {
        /* Body Big */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        height: 25px;
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 80%;
        padding-left: 5px;
        /* identical to box height, or 21px */
        /* Dark Grey */
        color: #5E5F5F;
    }
    .dropdownTextTimeline:hover {
        background: rgba(211, 107, 40, 0.1) !important;
        border-radius: 8px;

    }

    .scroll_container {
        width:100%;
        height:350px;
        color:#999999;
        border:2px solid #FFF;
        border-radius:10px;
        padding-bottom: 0px;
        overflow:hidden !important;
        text-align:justify;
    }

    .scroll_content {
        position:relative;
        width:420px;
        height:105%;
        top:-17px;
        padding:20px 0px 0px 0px;
        overflow-y:hidden !important;
        overflow-x:hidden !important;
    }

    .scroll_content_timeline {
        position:relative;
        width:100%;
        height:100%;
        top:0px;
        padding:0px 0px 0px 0px;
        overflow-y:hidden !important;
        overflow-x:hidden !important;
    }

    .track-vertical {
        top: 2px;
        bottom: 2px;
        right: 2px;
        width: 1px !important;
        z-index: inherit !important;
        border-radius: 3px;
        background: #D6D6D6;
        z-index: 1001 !important;
    }
    .track-vertical .thumb-vertical {
        position: relative;
        display: block;
        right: 1px;
        width: 3px !important;
        cursor: pointer;
        border-radius: inherit;
        background-color: #A9A9A9;
        
        z-index: 1001;
    }
    
    .track-horizontal {
        position: absolute;
        height: 6px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 3px;
    }
    .track-horizontal .thumb-horizontal {
        position: relative;
        display: block;
        height: 150%;
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(125, 149, 255, 0.993);
    }
    
    .view {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        overflow: scroll;
        margin-right: -15px;
        margin-bottom: -17px !important;
    }

    .dropdownBase {
        /* Gray300 */
        position: relative;
        left: 4px;
        width: 95%;
        padding-left: 20px;
        border-top: 1px solid #A9A9A9;
        color: #D22121;
        font-weight: 400;
        font-size: 12px;
        line-height: 125%;
        display: flex;
        pointer-events: none !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 16px;
        gap: 4px;
    }

    .cancelApply {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .imageButton {
        pointer-events: auto;
        position: relative;
        background-color: #FFF;
        border: none;
        cursor: pointer;
    }
    
    .checkbox-neo {
        width:16px;
        height: 16px;
        border-radius: 5px;
        vertical-align: middle;
        border: 1px solid #A9A9A9;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
    }
    .checkbox-neo:checked {
        appearance: auto;
        clip-path: inset(0% 0% 0% 0% round 5px);
        accent-color: #D36B28;
    }

    .ResetFilters {
        /* Reset filters */
        width: 71px;
        height: 14px;
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        align-self: flex-end;
        margin-right: 16px;
        cursor: pointer;
        background: none;
        border: none;
        /* identical to box height, or 14px */
        text-decoration-line: underline;
        /* Dark Grey */
        color: #5E5F5F;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .Frame6 {
        display: flex;
        padding: 0px;
        align-items: right;
        white-space: nowrap;
        width: 94vw;
        position: relative;
        height: 12px !important;
        filter: drop-shadow(0px 4px 4px rgba(229, 229, 229, 0.5));
    }

    .graphBackground {
        /* graph */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        top: 0px;
        gap: 24px;
        width: auto;
        margin: 1vw 1vw 1vw 1vw;
        /* Gray100 */
        background: #E6E6E6;
        border-radius: 16px;   
        flex-grow: 1;
    }

    .graphType {
        /* Graph type */
        width: 72px;
        height: 14px;
        /* Label Big */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 100%;
        margin-bottom: 5px;
        white-space: nowrap;
        /* identical to box height, or 14px */
        /* Gray400 */
        color: #8B8B8B;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .graphFrame {
        padding-left: 24px;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        gap: 5px;
    }

    .App-header {
    /* Graph */
        display: flex;
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 1vw;
        padding-left: 5vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        /* White */
        background: #FFFFFF;
        /* low shadow */
        box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.5);
        border-radius: 16px;
        /* Inside auto layout */
        position: relative;
        flex: 1;
        flex-basis: auto;
        align-self: stretch;
    }

    .App {
        /* Graph */
        /* low shadow */
        /* Inside auto layout */
        height: 100%;
        width: 100%;
    }

    .xAxisGrid {
        stroke: rgba(211, 107, 40, 0.2);
    }

    .graphHeading {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .timelineFrame {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 0;
        margin: 24px;
    }
    .timelineFrame2 {
        position: relative;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        width: auto;
        gap: 4px;
    }
    .playButton {
        border: none;
        width: 24px;
        background: none;
    }
    .playButton:hover {
        background: none;
        cursor: pointer;
    }

    .timelineSelects {
        /* */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        gap: 16px;
        width: 80px;
        height: 30px;
        /* White */
        background: #FFFFFF;
        border-radius: 16px;
        border: none;
    }

    .tooltip {
        max-width: 400px;
        border-radius: 16px;
        padding: 8px;
        font-size: 14px;
        font-family: 'Urbanist';
        font-weight: 600;
        background-color: #5E5F5F;
        position: absolute;
    }

    .tooltipMain {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .subtooltip_a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        position: relative;
        border-radius: 16px;
        margin: 0px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .subTooltip_b {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75%;
        max-width: 90%;
        justify-content: space-between;
        background-color: white;
        border-radius: 16px;
        gap: 4px;
    }

    .subTooltip_c {
        display: flex;
        align-items: center;
        flex-direction: row;
        text-align: right;
        width: max-content;
        background-color: white;
        border-radius: 16px;
        gap: 4px;
    }


    .subTooltipText {
        display: block;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        flex: 1;
        margin: 3px;
        margin-left: 12px;
        margin-right: 8px;
        max-width: 100%;
        background-color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .subTooltipTextNo {
        white-space: nowrap;
        align-items: flex-end;
        text-align: right !important;
        font-weight: 400;
        width: 30px;
        font-size: 12px;
        background-color: white;
        right: 12px;
        margin-right: 8px;
    }
    .circle {
        display: flex;
        align-items: center;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: white;
        border: 3px solid black;
        margin: 0px;
    }
    .subTooltip_d {
        display: flex;
        align-items: center;
        background-color: white;
        align-items: center;
        width: 15px;
        border-radius: 16px;
        
    }

    .LearnMoreInfo {
        /* Frame 78 */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        width: 100%;
        height: 20%;
        /* White */
        background: #FFFFFF;
        border-radius: 16px;
        /* Inside auto layout */
        flex: 1;
        order: 2;
        align-self: stretch;
    }
    .LearnMoreInfo2 {
        /* Frame 78 */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 24px;
        gap: 6px;
        /* White */
        background: #FFFFFF;
        border-radius: 16px;
        /* Inside auto layout */
    }

    .LearnMoreInfoText {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 12 px;
        line-height: 150%;
        /* or 21px */
        /* Dark Grey */
        color: #5E5F5F;
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    .VisitOurWebsiteButton {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;
        width: 132px;
        height: 37px;
        /* Orange */
        cursor: pointer;
        background: #D36B28;
        border: none;
        border-radius: 18px;
        /* Inside auto layout */    
        flex: none;
        order: 1;
        flex-grow: 0;   
    }

    .VisitOurWebsiteText {
        /* Button */
        width: 108px;
        height: 21px;
        /* Body Big Bold */
        font-family: 'Urbanist';
        font-style: normal;
        white-space: nowrap;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        /* White */
        color: #FFFFFF;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .contactus {
        color: #D36B28;
        text-decoration: underline !important;
    }

    .LearnMoreOuter {
        display: flex;
        flex-direction: column;
        flex: 'none';
        padding: '4vw 2vw 2vw 2vw';
        margin: '1vw 1vw 1vw 1vw';
    }
    .css-10650aq {
        height: 100vh;
    }

    .timelineSelectsText {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        left: -5px !important;
        /* identical to box height, or 14px */
        /* Gray400 */
        color: #8B8B8B;
    }
    option {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */
        /* Gray400 */
        color: #8B8B8B;
    }

    .missingData_subheading {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 125%;
    }

    .missingData_a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
        position: relative;
        border-radius: 16px;
        margin: 0px;
        margin-top: 8px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .missingData_b {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        flex: 1;
        justify-content: space-between;
        background-color: none;
        border-radius: 16px;
        gap: 4px;
    }
    .missingData_d {
        display: flex;
        align-items: center;
        background-color: none;
        align-items: center;
        width: 15px;
        margin-left: 10px;
        margin-right: 0px;
        border-radius: 16px;
    }

    .missingDataText {
        display: block;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        flex: 1;
        margin: 3px;
        margin-left: 8px;
        margin-right: 8px;
        max-width: 200px;
        background-color: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .label {
        font-weight: 400;
        font-size: 12px;
        line-height: 1em;
        max-height: 2em;
        background-color: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .main {
        width: 96vw;
        margin: 2vw;
        border-radius: 16px;
        background-color:  #F0F0F0;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    .css-1iwdxm {
        -webkit-box-flex:1;
        -webkit-flex-grow:1;
        -ms-flex-positive:1;
        flex-grow:1;
        overflow:auto;
        min-height:100%;
        padding-top: 0px !important;
        background-color:white;
        padding-bottom: 0px !important;
    }
       
}


@media only screen and (max-width: 1200px) {
    .ADITrackerByHeading_m {
        /* ADI Tracker by */
        width: auto;
        height: 25px;
        /* Heading 3 */
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        /* or 25px */
        /* Orange */
        color: #D36B28;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        
    }
       /* Frame 60 */
    .Frame60_m {
        /* Auto layout */
        position: absolute;
        right: 3vw;
        display: flex;
        flex-direction: row;
        align-items:    flex-end;
        padding: 0px;
        gap: 12px;
        width:  auto;
        height: auto;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .css-10650aq {
        -webkit-box-flex:1;
        -webkit-flex-grow:1;
        -ms-flex-positive:1;
        flex-grow:1;
        overflow:auto;
        min-height:100%;
        padding-top: 0px !important;
        background-color:#5E5F5F;
        padding-bottom: 0px !important;
    }
    .css-1iwdxm {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .main {
        width: 96vw;
        margin: 2vw;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
}