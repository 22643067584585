/*! react-dropdown v1.4.0 | (c) 2020 Trend Micro Inc. | MIT | https://github.com/trendmicro-frontend/react-dropdown */
.dropdown---dropdown---1yvIZ {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 20px;
  position: relative;
}
.dropdown---dropdown---1yvIZ *,
.dropdown---dropdown---1yvIZ *:before,
.dropdown---dropdown---1yvIZ *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  
}
.dropdown---dropdown-toggle---vMtjL {
  min-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 63px;
  width: 200px;
  height: 40px;
  outline: none;
  
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  padding-left: 56px;
  padding-right: 20px;
  color: #5E5F5F;
  /* White */
  background: #FFFFFF;
  border-radius: 8px;
  border-color: #FFFFFF;
  border-style: solid;
}


.dropdown---dropdown-toggle---vMtjL .dropdown---caret---3CkEt {
  display:  none;/* inline-block; */
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed transparent; /* #5E5F5F; */
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: 8px;
}
.dropdown---dropup---30DnN .dropdown---dropdown-toggle---vMtjL .dropdown---caret---3CkEt {
  display:  none;/* inline-block; */
  border-top: 0;
  border-bottom: 4px dashed #5E5F5F;
}
.dropdown---dropdown-toggle---vMtjL:focus {
  outline: 0;
}
.dropdown---dropdown-toggle---vMtjL.dropdown---btn-link---1xwS4:hover .dropdown---caret---3CkEt {
  border-top-color: #0096cc;
}
.dropdown---dropdown-toggle---vMtjL.dropdown---empty---zMmdA .dropdown---caret---3CkEt {
  margin-left: 0;
}
.dropdown---dropdown-menu---1fkH0 {
  position: absolute;
  top: 120%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  display: none;
  min-width: 0;
  padding: 8px;
  margin: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  text-align: left;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown---dropdown-menu---1fkH0.dropdown---pull-right---2juGH {
  right: 0;
  left: auto;
}
.dropdown---dropdown-menu---1fkH0 .dropdown---header---3pfXo {
  display: block;
  padding: 4px 16px;
  font-size: 12px;
  line-height: 20px;
  color: #888;
  white-space: nowrap;
}
.dropdown---dropdown-menu---1fkH0 .dropdown---divider---13uxG {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #eee;
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0 > .dropdown---menu-item---1LjoL {
  display: block;
  height: 36;
  max-height: 36;
  padding: 8px;
  clear: both;
  font-weight: 600;
  line-height: 100%;
  color: #5E5F5F;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: "default";
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---dropdown-submenu---11C1M > .dropdown---menu-item---1LjoL {
  padding-right: 32px;
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0 > .dropdown---menu-item---1LjoL:focus {
  outline: 0;
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0 > .dropdown---menu-item---1LjoL:hover,
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0 > .dropdown---menu-item---1LjoL:focus {
  text-decoration: none;
  color: #D36B28;
  background: rgba(211, 107, 40, 0.1);
  border-radius: 8px;
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---disabled---eCY9b {
  cursor: not-allowed;
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---disabled---eCY9b > .dropdown---menu-item---1LjoL,
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---disabled---eCY9b > .dropdown---menu-item---1LjoL:hover,
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---disabled---eCY9b > .dropdown---menu-item---1LjoL:focus {
  color: #D36B28;
}
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---disabled---eCY9b > .dropdown---menu-item---1LjoL:hover,
.dropdown---dropdown-menu---1fkH0 > .dropdown---menu-item-wrapper---3uAM0.dropdown---disabled---eCY9b > .dropdown---menu-item---1LjoL:focus {
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
.dropdown---dropdown-menu---1fkH0.dropdown---selected---1EK3y > .dropdown---menu-item-wrapper---3uAM0 > .dropdown---menu-item---1LjoL {
  padding: 4px 32px 4px 40px;
}
.dropdown---dropdown-menu---1fkH0.dropdown---selected---1EK3y > .dropdown---menu-item-wrapper---3uAM0.dropdown---active---2-a32 > .dropdown---menu-item---1LjoL:before {
  background-image: url(../public/static/icons/chevron-orange.svg);
  background-position: 0 2px;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 20px;
  margin: 0 8px 0 -24px;
  vertical-align: top;
  width: 16px;
}
.dropdown---dropdown-menu---1fkH0.dropdown---selected---1EK3y > .dropdown---menu-item-wrapper---3uAM0.dropdown---active---2-a32 > .dropdown---menu-item---1LjoL,
.dropdown---dropdown-menu---1fkH0.dropdown---selected---1EK3y > .dropdown---menu-item-wrapper---3uAM0.dropdown---active---2-a32 > .dropdown---menu-item---1LjoL:hover,
.dropdown---dropdown-menu---1fkH0.dropdown---selected---1EK3y > .dropdown---menu-item-wrapper---3uAM0.dropdown---active---2-a32 > .dropdown---menu-item---1LjoL:focus {
  text-decoration: none;
  outline: 0;
}
.dropdown---dropup---30DnN > .dropdown---dropdown-menu---1fkH0 {
  top: auto;
  bottom: 100%;
}
.dropdown---dropdown---1yvIZ.dropdown---open---1ju75 > .dropdown---dropdown-menu---1fkH0 {
  display: block;
}
.dropdown---dropdown-menu-wrapper---3gX-X {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #bbb;
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}
.dropdown---dropdown-menu-wrapper---3gX-X.dropdown---pull-right---2juGH {
  right: 0;
  left: auto;
}
.dropdown---dropdown-menu-wrapper---3gX-X > .dropdown---dropdown-menu---1fkH0 {
  position: static;
  vertical-align: top;
}
.dropdown---dropdown---1yvIZ.dropdown---open---1ju75 > .dropdown---dropdown-menu-wrapper---3gX-X {
  display: block;
}
.dropdown---dropdown---1yvIZ.dropdown---open---1ju75 > .dropdown---dropdown-menu-wrapper---3gX-X > .dropdown---dropdown-menu---1fkH0 {
  display: inline-block;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dropdown---dropdown-submenu---11C1M {
  /* position: relative; */
}
.dropdown---dropdown-submenu---11C1M:hover > .dropdown---dropdown-menu---1fkH0,
.dropdown---dropdown-submenu---11C1M.dropdown---open---1ju75 > .dropdown---dropdown-menu---1fkH0 {
  display: block;
}
.dropdown---dropdown-submenu---11C1M.dropdown---pull-left---176QY {
  float: none;
}
.dropdown---dropdown-submenu---11C1M.dropdown---pull-left---176QY > .dropdown---dropdown-menu---1fkH0 {
  left: auto;
  right: 100%;
  margin-left: 0;
}
.dropdown---dropdown-submenu---11C1M > .dropdown---menu-item---1LjoL:before {
  display: none;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-right-width: 0;
  border-left-color: #666;
  margin: 6px -16px 6px 0;
}
.dropdown---dropdown-submenu---11C1M > .dropdown---dropdown-menu---1fkH0 {
  max-height: 470px;
  top: 10%;
  left: 105%;
  margin-top: 0;
}

/*# sourceMappingURL=react-dropdown.css.map*/

@media only screen and (max-width: 739px) {
  .dropdown---dropdown-toggle---vMtjL {
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 63px;
    width: 64vw !important;
    height: 24px;
    outline: none;
    position: relative;
    right: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    padding-left: 56px;
    padding-right: 20px;
    color: #5E5F5F;
    /* White */
    background: #FFFFFF;
    border-radius: 8px;
    border-color: #FFFFFF;
    border-style: solid;
  }


  .dropdown---dropdown-submenu---11C1M:hover > .dropdown---dropdown-menu---1fkH0,
  .dropdown---dropdown-submenu---11C1M.dropdown---open---1ju75 > .dropdown---dropdown-menu---1fkH0 {
    display: block;
    position: fixed;
    top: 20vh;
    left: 50%;
    width: 90vw;
    max-width: 460px;
    transform: translateX(-50%);
    
  }
  .Frame1 > .dropdown---dropdown---1yvIZ.dropdown---open---1ju75 > .dropdown---dropdown-menu---1fkH0 {
    display: block;
    position: fixed;
    width: 90vw;
    max-width: 460px;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}